import {
  find, isPlainObject, filter, has,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';

const Season = (season) => {
  if (!isPlainObject(season)) return null;

  return {
    id: season.seasonId,
    name: season.seasonName,
  };
};

const Competition = (competition) => {
  if (!isPlainObject(competition)) return null;

  return {
    id: competition.competitionId,
    name: competition.competitionName,
  };
};

const Region = (region) => {
  if (!isPlainObject(region)) return null;

  return {
    id: region.regionId,
    name: region.regionName,
  };
};

const Competitor = (competitors, { side }) => {
  const competitor = find(competitors || [], { side });
  if (!isPlainObject(competitor)) return null;

  return {
    id: competitor.competitorId,
    name: competitor.name,
    shortName: competitor.shortName || side,
    side: competitor.side,
  };
};

export default (data) => {
  if (!isPlainObject(data)) return null;

  return {
    country: data.country || '',
    id: data.eventId || '',
    name: data.eventName || '',
    type: data.eventType || '',
    hasInPlay: data.hasInPlay || false,
    startsAt: data.startsAt || '',
    status: data.status || '',
    seasonId: data.seasonId || '',
    sportId: data.sportId || '',
    sport: data.sport || null,
    matchState: data.matchState || 'N_A',
    eventType: data.eventType || 'N_A',
    isUSAView: data.isUsaView || false,
    markets: data.markets?.nodes || [],
    season: Season(data.season),
    competition: Competition(data.competition),
    region: Region(data.region),
    home: Competitor(data.competitors, { side: 'HOME' }),
    away: Competitor(data.competitors, { side: 'AWAY' }),
    source: data.source,
    hasPlayerProps:
      has(data, 'hasPlayerProps')
        ? data.hasPlayerProps
        : !!filter(data.eventHasPlayerPropsByEventId?.nodes, { hasPlayerProps: true })?.length,
    hasSgp:
      has(data, 'hasSgp')
        ? data.hasSgp
        : !!filter(data.eventHasSgpAvailablesByEventId?.nodes, { hasSgp: true })?.length,
    hasMicroMarkets:
      has(data, 'hasMicroMarkets')
        ? data.hasMicroMarkets
        : !!filter(data.eventHasMicroMarketsByEventId?.nodes, { hasMicroMarkets: true })?.length,
    detailsSource: eventHelpers.getEventDetailsSource(data),
  };
};
