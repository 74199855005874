/* eslint-disable no-param-reassign */
import {
  assign,
  cloneDeep,
  each,
  filter,
  find,
  findIndex,
  isEqual,
  isFunction,
  map,
} from 'lodash';

const THROTTLE_TIME_IN_MS = 100;
const THROTTLE_UNTIL_COUNT = 500;

export const createMarketBuffer = (callback) => {
  if (!isFunction(callback)) throw new Error('Callback for market buffer must be a function');

  let buffer = null;
  let timer = 0;

  const tick = () => {
    const incomingMarkets = Object.values(buffer);
    if (!incomingMarkets.length) return;
    callback(incomingMarkets);
    buffer = {};
  };

  return {
    start() {
      if (timer) clearInterval(timer);
      buffer = {};
      timer = setInterval(tick, THROTTLE_TIME_IN_MS);
    },
    stop() {
      clearInterval(timer);
      buffer = null;
    },
    append(market) {
      if (!this.active) throw new Error('Call start() before appending to market buffer');
      buffer[market.marketId] = market;
      if (Object.keys(buffer).length >= THROTTLE_UNTIL_COUNT) tick();
    },
    get active() {
      return !!buffer;
    },
  };
};

/**
 * Merges WebSocket markets into GraphQL markets.
 *
 * Note: mutates original array
 *
 * @param {Array} currentMarkets GraphQL markets
 * @param {Array} incomingMarkets WebSocket markets
 * @param {String} eventId Event ID by which to filter payloads
 */
export const mergeIncomingMarkets = (currentMarkets, incomingMarkets, eventId = '') => {
  const incomingMarketIds = map(incomingMarkets, ({ marketId }) => marketId);
  const marketsToUpdate = filter(currentMarkets, ({ marketId }) => incomingMarketIds.includes(marketId));

  each(incomingMarkets, (payload) => {
    if (eventId && eventId !== payload.eventId) return;

    const foundMarket = find(marketsToUpdate, { marketId: payload.marketId });
    let updateEventMarkets = false;

    if (!foundMarket) {
      if (payload.displayStatus !== 'ON') return;
      updateEventMarkets = true;

      const newMarket = {
        marketType: payload.marketType,
        marketCode: payload.marketType.marketCode,
        marketId: payload.marketId,
        selections: payload.selections,
        source: payload.source,
        status: payload.status,
        marketSummary: payload.marketSummary,
        displayStatus: payload.displayStatus,
        offeringStatus: payload.offeringStatus,
        inPlay: payload.inPlay,
      };
      currentMarkets.push(newMarket);
    } else {
      if (payload.displayStatus !== 'ON') {
        const marketIndex = findIndex(currentMarkets, ({ marketId }) => marketId === payload.marketId);
        currentMarkets.splice(marketIndex, 1);
        return;
      }

      const changedMarketFields = {};
      if (foundMarket.displayStatus !== payload.displayStatus) {
        changedMarketFields.displayStatus = payload.displayStatus;
        updateEventMarkets = true;
      }
      if (foundMarket.offeringStatus !== payload.offeringStatus) {
        changedMarketFields.offeringStatus = payload.offeringStatus;
        updateEventMarkets = true;
      }
      if (foundMarket.inPlay !== payload.inPlay) {
        changedMarketFields.inPlay = payload.inPlay;
        updateEventMarkets = true;
      }
      if (!isEqual(foundMarket.marketSummary, payload.marketSummary)) {
        changedMarketFields.marketSummary = payload.marketSummary;
        updateEventMarkets = true;
      }

      if (updateEventMarkets) {
        assign(foundMarket, changedMarketFields);
      }
      each(foundMarket.selections, (selection) => {
        const foundSelection = find(
          payload.selections,
          (s) => (s.selectionType.selectionCode === selection.selectionType.selectionCode)
            && isEqual(s.selectionType.params, selection.selectionType.params),
        );
        if (!foundSelection) return;

        let changedPrice = selection.price.changedPrice || null;
        if (foundSelection.price.decimalValue > selection.price.decimalValue) changedPrice = 'up';
        if (foundSelection.price.decimalValue < selection.price.decimalValue) changedPrice = 'down';

        if (foundSelection.price.decimalValue > selection.price.decimalValue
          || foundSelection.price.decimalValue < selection.price.decimalValue
          || selection.displayStatus !== foundSelection.displayStatus
          || selection.offeringStatus !== foundSelection.offeringStatus) {
          updateEventMarkets = true;
        }
        const newPrice = { ...foundSelection.price, changedPrice };
        assign(selection, {
          price: newPrice,
          selectionType: foundSelection.selectionType,
          displayStatus: foundSelection.displayStatus,
          offeringStatus: foundSelection.offeringStatus,
        });
      });
    }
  });
};

/**
 * Merges WebSocket markets into GraphQL markets.
 *
 * Note: mutates original array
 *
 * @param {Array} currentMarkets GraphQL markets
 * @param {Array} incomingMarkets WebSocket markets
 * @param {String} eventId Event ID by which to filter payloads
 */
export const mergeIncomingMarket = (currentMarkets, incomingMarket) => {
  const markets = currentMarkets;

  const foundMarket = markets[incomingMarket.marketId];
  let updateEventMarkets = false;

  if (!foundMarket) {
    if (incomingMarket.displayStatus !== 'ON') return { update: false };
    updateEventMarkets = true;

    const newMarket = {
      marketType: incomingMarket.marketType,
      marketCode: incomingMarket.marketType.marketCode,
      marketId: incomingMarket.marketId,
      selections: incomingMarket.selections,
      source: incomingMarket.source,
      status: incomingMarket.status,
      marketSummary: incomingMarket.marketSummary,
      displayStatus: incomingMarket.displayStatus,
      offeringStatus: incomingMarket.offeringStatus,
      inPlay: incomingMarket.inPlay,
    };
    return {
      market: newMarket,
      update: true,
    };
  }
  if (incomingMarket.displayStatus !== 'ON') {
    return {
      deleteMarket: true,
    };
  }

  const changedMarketFields = {};
  if (foundMarket.displayStatus !== incomingMarket.displayStatus) {
    changedMarketFields.displayStatus = incomingMarket.displayStatus;
    updateEventMarkets = true;
  }
  if (foundMarket.offeringStatus !== incomingMarket.offeringStatus) {
    changedMarketFields.offeringStatus = incomingMarket.offeringStatus;
    updateEventMarkets = true;
  }
  if (foundMarket.inPlay !== incomingMarket.inPlay) {
    changedMarketFields.inPlay = incomingMarket.inPlay;
    updateEventMarkets = true;
  }
  if (!isEqual(foundMarket.marketSummary, incomingMarket.marketSummary)) {
    changedMarketFields.marketSummary = incomingMarket.marketSummary;
    updateEventMarkets = true;
  }

  if (updateEventMarkets) {
    assign(foundMarket, changedMarketFields);
  }
  each(foundMarket.selections, (selection) => {
    const foundSelection = find(
      incomingMarket.selections,
      (s) => (s.selectionType.selectionCode === selection.selectionType.selectionCode)
        && isEqual(s.selectionType.params, selection.selectionType.params),
    );
    if (!foundSelection) return;

    let changedPrice = selection.price.changedPrice || null;
    if (foundSelection.price.decimalValue > selection.price.decimalValue) changedPrice = 'up';
    if (foundSelection.price.decimalValue < selection.price.decimalValue) changedPrice = 'down';

    if (foundSelection.price.decimalValue > selection.price.decimalValue
      || foundSelection.price.decimalValue < selection.price.decimalValue
      || selection.displayStatus !== foundSelection.displayStatus
      || selection.offeringStatus !== foundSelection.offeringStatus) {
      updateEventMarkets = true;
    }
    const newPrice = { ...foundSelection.price, changedPrice };
    assign(selection, {
      price: newPrice,
      selectionType: foundSelection.selectionType,
      displayStatus: foundSelection.displayStatus,
      offeringStatus: foundSelection.offeringStatus,
    });
  });
  return {
    market: foundMarket,
    update: updateEventMarkets,
  };
};

/**
 * Merges WebSocket markets into GraphQL markets for multiple events.
 *
 * @param {Array} originalEvents GraphQL events
 * @param {Array} incomingMarkets WebSocket markets
 * @returns A new array of updated events based on incoming markets
 */
export const updateEventListMarkets = (originalEvents, incomingMarkets) => {
  const events = cloneDeep(originalEvents);
  each(incomingMarkets, (payload) => {
    if (payload.source !== 'INTERNAL_AGGREGATION') return;

    const eventIndex = findIndex(events, { eventId: payload.eventId });
    const markets = events[eventIndex].markets.nodes;
    mergeIncomingMarkets(markets, [payload]);
    events[eventIndex].markets.nodes = markets;
  });
  return events;
};
