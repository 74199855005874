import {
  find, ceil, lowerCase, filter, includes,
} from 'lodash';
import { sortCompetitions } from '@/services/helpers/competitions';

export default {
  initialized: (state) => state.initialized,
  bootstrapped: (state, getters) => getters.initialized || !getters.userToken,
  scrollPosition: (state) => state.scrollPosition,
  sports: (state) => state.sports || [],
  sportById: (state) => (id) => find(state.sports || [], { sportId: id }),
  sportByLabel: (state) => (sportLabel) => find(state.sports || [], { sportLabel }),
  events: (state) => state.events || [],
  eventsLoading: (state) => state.eventsLoading,
  eventsPageLoading: (state) => state.eventsPageLoading,
  eventsTotalCount: (state) => state.eventsTotalCount,
  eventsPage: (state) => state.eventsPage,
  eventsPageSize: (state) => state.eventsPageSize,
  eventsLastPage: (state) => {
    const { eventsTotalCount, eventsPageSize } = state;
    return ceil(eventsTotalCount / eventsPageSize);
  },
  eventsFilters: (state) => state.eventsFilters,
  eventsPagination: (state) => state.eventsPagination,
  competitions: (state) => sortCompetitions(state.competitions),
  competitionsLoading: (state) => state.competitionsLoading,
  socketUpdatedEvent: (state) => state.socketUpdatedEvent,
  oddFormats: (state) => state.oddFormats,
  selectedOddFormat: (state) => state.selectedOddFormat,
  userToken: (state) => state.userToken,
  username: (state) => state.username,
  operator: (state) => state.operator,
  loginErrorMessage: (state) => state.loginErrorMessage,
  changePasswordAttributes: (state) => state.changePasswordAttributes,

  selectedSportId: (state) => state.selectedSportId,
  selectedCompetitionId: (state) => state.selectedCompetitionId,
  dateRanges: (state) => state.dateRanges,
  selectedDateRange: (state) => state.selectedDateRange,

  event: (state) => state.event,
  eventMarkets: (state) => state.eventMarkets || null,
  getEventMarketById: (state) => (marketId) => state.eventMarkets[marketId],
  eventDisplayMarkets: (state) => state.eventDisplayMarkets || null,
  allMarketParameters: (state) => (sportLabel) => filter(state.allMarketParameters, (parameter) => parameter.sport === sportLabel),
  eventInternalMarkets: (state) => filter(state.eventMarkets, (market) => market.source === 'INTERNAL_AGGREGATION'),
  eventExternalMarkets: (state) => filter(state.eventMarkets, (market) => market.source !== 'INTERNAL_AGGREGATION'),
  selectedEventNavigationTab: (state) => state.selectedEventNavigationTab,
  eventTeamPlayers: (state) => state.eventTeamPlayers,
  marketsFilters: (state) => lowerCase(state.marketsFilters) || '',
  eventLoading: (state) => state.eventLoading,
  eventFound: (state) => !state.eventLoading && state.event,
  selectedMarketId: (state) => state.selectedMarketId,
  socketConnected: (state) => !!state.connection,

  mobSportsOddsFilterToggled: (state) => state.mobSportsOddsFilterToggled,
  mobEventsFilterToggled: (state) => state.mobEventsFilterToggled,

  searchToggled: (state) => state.search.toggled,
  searchQuery: (state) => state.search.query,
  searchLoading: (state) => state.search.loading,
  searchRecentResults: (state) => state.search.recentResults,
  searchCompetitorResults: (state) => state.search.competitorResults,
  searchEventResults: (state) => state.search.eventResults,
  searchResultsEmpty: (state, getters) => !getters.searchRecentResults.length && !getters.searchCompetitorResults.length && !getters.searchEventResults.length,
  searchResultsNotFound: (state, getters) => getters.searchQuery && !getters.searchCompetitorResults.length && !getters.searchEventResults.length,
  searchSelection: (state) => state.search.selection,
  marketGroupsBySport: (state) => state.marketGroupsBySport,
  selectedMarketGroup: (state) => state.selectedMarketGroup,
  latestLoadedMarketGroupSport: (state) => state.latestLoadedMarketGroupSport,

  betslip: (state) => state.betslip,
  betslipEnabled: (state) => includes(state.operators[0]?.feature, 'BETSLIP'),
  operatorsList: (state) => state.operatorsList,
  cashoutAmount: (state) => state.cashoutAmount,
  betslipError: (state) => state.betslipError,
};
